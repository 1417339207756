.profile_input{
    width:100%!important;
    color: #23272B !important;
}
.profile_input:disabled{
    background-color:#E2E2E3!important;
}
.formgroup{
    display: flex!important;
    align-items: flex-start!important;
    flex-direction: column!important;
    margin-bottom: 0.5rem!important;
}
.formLabel{
    color:#92929D !important;
    margin-bottom: 0 !important;
    font-size: 14px;
}
.col_pl10{
    /* padding-left: 5%;
    padding-right: 5%; */
}
.formgroup_1{
    display: flex!important;
    justify-content: flex-start!important;
}