.modalinput{
    background-color: #E2E2E3;
    border-radius: 6px !important;
    border: 1px solid #ddd !important;
    box-sizing: border-box !important;
    padding: 8px 16px !important;
    /* height: 40px !important; */
    line-height: normal !important;
    outline: none !important;
    width: 100%;
}
.modalinput::placeholder{
    color: #23272B !important;
}
.close {
    position: absolute;
    top: 1.75rem;
    right: 3rem;
}

.close::before,
.close::after {
    position: absolute;
    content: ' ';
    background-color: #4d4d4d;
    left: 12px;
    width: 2px;
    height: 12px;
    cursor: pointer;
}
.close::before {
    transform: rotate(45deg);
}
.close::after {
    transform: rotate(-45deg);
}
.scrollbar{
    max-height: 8rem;
    width: 100%;
    overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar
{
  width: 5px !important;
  display: block !important;
  height: 6px !important;
}
/*定义滚动条轨道 内阴影+圆角*/
/* .scrollbar1::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #F5F5F5;
} */
/*定义滑块 内阴影+圆角*/
.scrollbar::-webkit-scrollbar-thumb{
  border-radius: 10px !important;
  background-color: #555 !important;
}
.unlockBtn{
    font-size: 0.8rem;
    background-color: #ffffff;
    border:1px solid #000000;
    border-radius: 5px;
    white-space: nowrap;
    display: flex;
    align-items: center;
}