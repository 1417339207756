.LogoContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 0.8rem;
}
.LogoContent_Top{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2rem;
}
.commoninput{
    background-color: #E2E2E3;
    border-radius: 6px !important;
    border: 1px solid #ddd !important;
    box-sizing: border-box !important;
    padding: 8px 16px !important;
    height: 40px !important;
    line-height: normal !important;
    outline: none !important;
}
.commoninput::placeholder{
    color: #23272B;
}
.hr{
    border-color: #bfbfbf;
    border-width: 2px;
    margin-top:1rem;
    margin-bottom: 1.5rem;
}
.CreateBtn{
    display: inline;
    font-weight: 600;
    border-bottom: 1px solid #8b8b8b;
    cursor: pointer;
}
.shadowBox{
    max-width: 18rem;
    height: 2.5rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 6px;
    box-shadow: -1px 1px 8px 0px rgb(0 0 0 / 22%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.shadowBox:hover{
   transform: scale(1.1);
   transition: 400ms;
}
.InterestLinkBox{
    text-align: center;
    margin-top:2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content:space-evenly;
}
.InterestLink:hover{
    text-decoration:none
}