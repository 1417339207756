.reset_card{
    background: #007BFF;
    min-height: 20rem;
}
.reset_card_head{
    background: #007BFF;
    padding-top: 3.5rem;
}
.color_w{
    color: #ffffff;
}
.reset_card_body{
    width: 75%;
    margin: 0 auto;
    padding-top: 0;
}
.resetbutton{
    color: #ffffff;
    border-color:#ffffff;
    background: #007BFF
}

